<template>
  <line-chart
    title="RPMV vs Objectif"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
  ></line-chart>
</template>

<script>
import axios, { cacheTime } from "@axios";
import { colors } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

import LineChart from "@/components/common/charts/LineChart";

export default {
  name: "RpmvSessionsChart",
  components: {
    LineChart,
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      series: [
        {
          name: "RPMV",
          data: [],
        },
        {
          name: "Objectif",
          data: [],
        },
      ],
      colors: [colors.rpm, colors.objective], // "#F44336", "#00d4bd", "#9C27B0",
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };
        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        for (const x of categories) {
          mainData[x] = {
            ca: null,
            objRevenue: null,
            sessions: null,
            views: null,
            rpms: null,
            rpmv: null,
          };
        }
        // store dispatch  getdetailedRevenue
        const detailedRevenueData = await this.$store.dispatch(
          "premium/getDetailedRevenue",
          queryParams
        );

        const { data: detailedAudienceData } = await axios.get(
          "/programmatic/detailed-audience-monthly-report",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        delete queryParams["partnersconfigs"];

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
          mainData[data["date"]]["views"] = data["views"];
        });
        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        const mainDataWithRpm = this.calculateRPM(categories, mainData);

        this.series = [
          {
            name: "RPMV",
            data: categories.map(
              (category) => mainDataWithRpm[category]["rpmv"]
            ),
          },
        ];
      }
    },
    calculateRPM(categories, mainData) {
      for (const x of categories) {
        if (mainData[x]["ca"] > 0 && mainData[x]["sessions"] > 0)
          mainData[x]["rpms"] = (
            (mainData[x]["ca"] / mainData[x]["sessions"]) *
            1000
          ).toFixed(3);

        if (mainData[x]["ca"] > 0 && mainData[x]["views"] > 0)
          mainData[x]["rpmv"] = (
            (mainData[x]["ca"] / mainData[x]["views"]) *
            1000
          ).toFixed(3);
      }

      return mainData;
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
