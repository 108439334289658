<template>
  <div>
    <p class="text-2xl mb-6">Dashboard Programmatique</p>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters :showGroupCASwitch="true"></filters>
        </v-col>
      </v-row>
    </v-card>

    <top-cards
      :month="monthDate"
      :partners-configs="partnersConfigs"
      :sites="sites"
    ></top-cards>

    <v-row>
      <v-col v-if="groupCA" cols="8">
        <ca-objectives-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></ca-objectives-chart>
      </v-col>
      <v-col cols="8" v-else>
        <ca-by-partner-view-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></ca-by-partner-view-chart>
      </v-col>
      <v-col cols="4">
        <c-a-distribution-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></c-a-distribution-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <sessions-objectives-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></sessions-objectives-chart>
      </v-col>
      <v-col cols="6">
        <rpms-objectives-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></rpms-objectives-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <rpmv-sessions-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></rpmv-sessions-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaByPartnerViewChart from "@/views/cards/premium/CaByPartnerViewChart";
import CaObjectivesChart from "@/views/cards/premium/CaObjectivesChart";
import TopCards from "@/views/cards/premium/TopCards";
import Filters from "@/views/filters/premium/Filters";
import CADistributionChart from "@/views/cards/premium/CADistributionChart";
import SessionsObjectivesChart from "@/views/cards/premium/SessionsObjectivesChart";
import RpmsObjectivesChart from "@/views/cards/premium/RPMSObjectivesChart";
import RpmvSessionsChart from "@/views/cards/premium/RPMVObjectivesChart";
import { saveRmraView } from "@/utils/rmr-analytics";
export default {
  name: "GeneralDecoupled",
  components: {
    Filters,
    TopCards,
    CaObjectivesChart,
    CaByPartnerViewChart,
    CADistributionChart,
    SessionsObjectivesChart,
    RpmsObjectivesChart,
    RpmvSessionsChart,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  created() {
    let monthDate = new Intl.DateTimeFormat("fr-FR")
      .format(new Date())
      .split("/");
    this.$store.dispatch(
      "premium/updateMonthDate",
      `${monthDate[2]}-${monthDate[1]}`
    );
    this.$store.dispatch("premium/updatePartnerViewsGroups", [9]);
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    monthDate() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnersConfigs() {
      return this.$store.getters["premium/getPartnerViews"];
    },
    groupCA() {
      console.log(
        "General groupCA computed",
        this.$store.getters["premium/getGroupCA"]
      );
      return this.$store.getters["premium/getGroupCA"];
    },
  },
};
</script>
