<template>
  <line-chart
    title="Sessions vs Objectif"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
  ></line-chart>
</template>

<script>
import axios, { cacheTime } from "@axios";
import { colors } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";
import LineChart from "@/components/common/charts/LineChart";

export default {
  name: "SessionsSessionsChart",
  components: {
    LineChart,
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      series: [
        {
          name: "Sessions",
          data: [],
        },
        {
          name: "Objectif",
          data: [],
        },
      ],
      colors: [colors.sessions, colors.objective], // "#2da0ed", "#00d4bd", "#9C27B0",
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        let queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };
        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        for (const x of categories) {
          mainData[x] = {
            ca: null,
            objRevenue: null,
            sessions: null,
            objSessions: null,
          };
        }
        /* const detailedRevenueResponse = await this.$store.dispatch(
          "/premium/getDetailedRevenue",
          queryParams
        ); */
        const { data: detailedAudienceData } = await axios.get(
          "/programmatic/detailed-audience-monthly-report",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        /* const totalResponse = await axios.get(
          "/programmatic/total-monthly-report",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );*/

        delete queryParams["partnersconfigs"];
        queryParams["objtype"] = "sessions";
        const { data: objectivesSessionsData } = await axios.get(
          "/objectives/",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
        });

        const objectiveSessionsMonth = objectivesSessionsData["value"];
        const objectiveSessionsDay = objectiveSessionsMonth / categories.length;

        for (const x of categories) {
          mainData[x]["objSessions"] = objectiveSessionsDay;
        }

        this.series = [
          {
            name: "Sessions",
            data: categories.map((category) => mainData[category]["sessions"]),
          },
          {
            name: "Objectif",
            data: categories.map(
              (category) => mainData[category]["objSessions"]
            ),
          },
        ];
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
