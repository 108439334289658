<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <partners-groups-filter></partners-groups-filter>
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <partners-views-filter></partners-views-filter>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <date-filter
          store="premium"
          storeGetter="getMonthDate"
          storeUpdater="updateMonthDate"
          format="month"
          :isRange="false"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <site-groups-filter store="premium" />
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <sites-filter store="premium" />
      </v-col>

      <v-col cols="3">
        <v-btn
          @click="resetFilters"
          class="pt-7 pb-6 mt-0 my-1"
          outlined
          color="secondary"
        >
          Réinitialiser
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";
import PartnersGroupsFilter from "@/views/filters/premium/PartnersGroupsFilter";
import PartnersViewsFilter from "@/views/filters/premium/PartnersViewsFilter";
import { mdiCached } from "@mdi/js";

export default {
  name: "Filters",
  components: {
    SitesFilter,
    SiteGroupsFilter,
    PartnersGroupsFilter,
    PartnersViewsFilter,
    DateFilter,
    mdiCached,
  },
  data() {
    return {
      icons: {
        mdiCached,
      },
    };
  },
  methods: {
    resetFilters() {
      let monthDate = new Intl.DateTimeFormat("fr-FR")
        .format(new Date())
        .split("/");
      this.$store.dispatch(
        "premium/updateMonthDate",
        `${monthDate[2]}-${monthDate[1]}`
      );
      this.$store.dispatch("premium/updateSiteGroups", []);
      this.$store.dispatch("premium/updatePartnerViewsGroups", []);
    },
  },
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
